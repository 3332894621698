import { createStore } from 'vuex'
import rootMutations from "./mutations.js";
import rootActions from "./actions.js";
import rootGetters from "./getters.js";
import { auth } from "./modules/auth"
import createPersistedState from "vuex-persistedstate";

export default createStore({
   state() {
    return {
      cart: [],
      total: 0,
      qty: 0,
      products: [
        {
          id: 1,
          type: "Smartphone",
          brand: "Apple",
          model: "iPhone 12 Pro",
          color: "Graphite",
          capacity: "128 GB",
          imgSrc: "",
          price: 999,
        },
      ],
      data_checkout_information : {
        'email' :  null,
        'first_name' : null,
        'last_name' :null,
        'address' :null,
        'pincode' : null,
        'city' : null,
        'state' : null,
        'country' : null,
        'type' : null,
        'phone_number' : null,
        'company' :null,
        'delivery_instruction' : null,
      } 
    };
  },
  modules: {
    auth
  },
  plugins: [createPersistedState({
    path :['auth']
  })],
  mutations: rootMutations,
  actions: rootActions,
  getters: rootGetters,
  hostname : "darshika"
})
