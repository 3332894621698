<template>
    <section class="mille-crepes">
        <div class="mille-inner-crepes">
            <h2>Recommended Products</h2>
            <Carousel :settings="settings" :breakpoints="breakpoints">
                <Slide v-for="slide in recomendedItems" :key="slide">
                
                    <div class="carousel__item">
                        <div class="card border-0">
                            
                            <div class="card-image">
                                        <router-link :to="{ name: 'product', params: { id: slide.id } }">
                                            <img :src=slide.image />
                                        </router-link>
                                    </div>
                            <div class="intro">
                                <p class="name">{{ slide.name }}</p>
                                <p class="Price">{{ slide.currency }} {{ slide.price }}</p>
                            </div>
                        </div>
                    </div>
                </Slide>

                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
            <router-link to="/"  class="ct-button">
                Shop Mille Crepe
            </router-link>
        </div>
    </section>
</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
export default {
    name: 'Pies',
    components: {
        Carousel,
        Slide,
        Navigation
    },
    data() {
        return {
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3.5,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },
            recomendedItems:[],
            records : [],
        }
    },
    created() {
        this.getProduct();
    },
    watch: {
        
        records(value) {
            console.log('HI watch value')
            console.log(value)
            this.recomendedItems = []

            for(var i=0; i < value.length; i++){

                var dat = value[i];
                let arrayLength = dat.products.length;

                if(arrayLength > 0) {
                    var dataObject = {
                        id: value[i].id,
                        image: value[i].products[0].images[0].url,
                        name: value[i].products[0].name,
                        price: value[i].products[0].prices.price,
                        currency: value[i].products[0].prices.currency,
                    }

                    this.recomendedItems.push(dataObject)
                }
            }
        }
    },
    methods: {
        getProduct(){
            this.$store.dispatch("SHOW_RECOMMENDED_PRODUCTS")
            .then((response) => {
                console.log("SHOW_RECOMMENDED_PRODUCTS--------->", response);
                this.records = response.data;
                console.log('recomended products coming in')
                console.log(this.records)
            }).catch((err) => {
                console.log(err)
            });
        }

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mille-inner-crepes {
    padding: 50px 0;
}
.mille-inner-crepes h2 {
    font-size: 32px;
    margin-bottom: 50px;
    font-family: "PT Sans";
}
.mille-inner-crepes .row {
    padding: 0 5px;
}
.mille-inner-crepes .row > div {
    padding: 0 5px;
}
.mille-inner-crepes .card {
    height: 100%;
    cursor: pointer;
}
.mille-inner-crepes .card .card-img {
    position: relative;
}
.mille-inner-crepes .card .card-img img {
    height: 640px !important;
    width: 519px !important;
    object-fit: cover;
    object-position: center;
}
.mille-inner-crepes .card .card-img a {
    display: inline-block;
    font-weight: 400;
    transition: opacity .2s ease-in-out;
    cursor: pointer;
    padding: 10px 30px;
    line-height: 22px;
    text-transform: capitalize;
    text-decoration: none !important;
    letter-spacing: 0px;
    background-color: #ffffff;
    color: #000;
    border: 1px solid #ffffff;
    border-radius: 0;
    min-width: 150px;
    text-align: center;
    margin: 10px 10px 0;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s ease;
}

.mille-inner-crepes .card .card-image img{
    width: 100%;
    height: 710px;
    object-fit: contain;
    object-position: center;

}

.mille-inner-crepes .card:hover .card-img a{
    opacity: 1;
    visibility: visible;
}
.mille-inner-crepes .card .intro {
    padding: 20px 0 10px;
}
.mille-inner-crepes .card .intro p.name {
    font-size: 17px;
}
.mille-inner-crepes .card .intro p.Price {
    font-size: 14px;
}
.mille-inner-crepes a.ct-button {
    margin-top: 20px;
    margin-bottom: 50px;
}
@media(max-width: 1170px){
    .mille-inner-crepes .card .card-img img{
        height: 350px;
    }
}
@media(max-width: 767px){
    .mille-inner-crepes .card .card-img img{
        height: 450px;
    }
}
</style>
