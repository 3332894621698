import axios from "axios";
export const auth = {
    state: {
        auth_status: false,
        auth_token: '',
        auth_info: {
            user_id: '',
            first_name: '',
            last_name: '',
            email: '',
        },
        order_date_time : {
            date : '',
            time : '',
            address : '',
            delivery_method : '',
        },
        cart_count : 0,
    },
    getters: {
        GET_AUTH_STATUS(state) {
            return state.auth_status;
        },
        GET_AUTH_TOKEN(state) {
            return state.auth_token;
        },
        GET_AUTH_INFO(state) {
            return state.auth_info;
        },
        GET_ORDER_DATE_TIME(state) {
            return state.order_date_time;
        },
        GET_CART_COUNT(state){
            return state.cart_count;
        }
    },
    actions: {
        LOGIN(context, loginData,state) {
            console.log("base_url",process.env.VUE_APP_BASE_URL );
            return new Promise((resolve, reject) => {
                 axios.post(process.env.VUE_APP_BASE_URL + '/login', loginData)
                    .then((res) => {
                        context.commit('SET_AUTH_TOKEN',res.data.token)
                        context.commit('SET_AUTH_INFO',res.data.user)
                        resolve(res)
                        state.auth_status=false;

                    }).catch((err) => {
                        reject(err)
                    });
            })
        },

        ODER_DATE_TIME(context,state){
            context.commit('SET_ORDER_INFO',state)
        },

        REGISTER(context, registerData,state){
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_URL + '/register', registerData)
                    .then((res) => {
                        // console.log(res.data)
                        context.commit('SET_AUTH_TOKEN',res.data.token)
                        context.commit('SET_AUTH_INFO',res.data.user)
                        resolve(res)
                        state.auth_status=false;
                    }).catch((err) => {
                        // console.log(err.response.data.errors)
                        reject(err)
                    });
            })
        },

        LOGOUT(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_URL + '/logout')
                    .then((res) => {
                        // console.log(res.data)
                        context.commit('SET_AUTH_LOGOUT')
                        resolve(res)
                    }).catch((err) => {
                        // console.log(err.response.data.errors)
                        reject(err)
                    });
            })
        },

        FORGOT(context, forgotData,state){
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_URL + '/forgot', forgotData)
                    .then((res) => {
                        // console.log(res.data)
                        resolve(res)
                        state.auth_status=false;
                    }).catch((err) => {
                        // console.log(err.response.data.errors)
                        reject(err)
                    });
            })
        },

        SHOW_CART_DETAILS(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_URL + '/cart?shop=1', {
                      headers: { 'X-Requested-With': 'XMLHttpRequest' }
                    })
                    .then((res) => {
                        // console.log("res--vuex",res.data.carts.length);
                        context.commit('SET_CART_COUNT', res.data.carts.length)
                        context.commit('SET_SHOPS', res.data.shop_addresses)
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    });
            })
        },

        STORE_CART_DETAILS(context, createData) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_URL + '/cart', createData, {
                      headers: { 'X-Requested-With': 'XMLHttpRequest' }
                    })
                    .then((res) => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    });
            })
        },

        SHOW_MY_ORDERS(context) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_BASE_URL + '/ongoing/order', {
                      headers: { 'X-Requested-With': 'XMLHttpRequest' }
                    })
                    .then((res) => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    });
            })
        },

        STORE_ORDER_DETAILS(context, createData) {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_BASE_URL + '/order', createData, {
                      headers: { 'X-Requested-With': 'XMLHttpRequest' }
                    })
                    .then((res) => {
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    });
            })
        },
    },
    mutations: {

        SET_AUTH_TOKEN(state,token){
            state.auth_token=token;
            state.auth_status=true;
        },

        SET_AUTH_INFO(state,info){
            state.auth_info.user_id=info.id;
            state.auth_info.first_name=info.first_name;
            state.auth_info.last_name=info.last_name;
            state.auth_info.email=info.email;
        },

        SET_ORDER_INFO(state,info){
            state.order_date_time.date=info.date;
            state.order_date_time.time=info.time;
            state.order_date_time.address=info.address;
            state.order_date_time.delivery_method=info.delivery_method;
        },

        SET_CART_COUNT(state, info){
            state.cart_count = info;
        },

        SET_SHOPS(state, info){
            state.shop_address = info;
        },

        SET_AUTH_LOGOUT(state){
            state.auth_token=null;
            state.auth_status=false;
            state.auth_info = {
                user_id: '',
                first_name: '',
                last_name: '',
                email: '',
            },
            state.order_date_time = {
                date : '',
                time : '',
                address : '',
                delivery_method : '',
            }
        },
    },
}