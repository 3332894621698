import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store';
  
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "Home"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../components/NotFound.vue'),
    meta: {
      title: "Site not found!"
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue'),
    meta: {
        title: "About Us"
    }
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import('../views/FaqsView.vue'),
    meta: {
        title: "FAQs"
    }
  },
  {
    path: '/contact',
    name: 'Contact Us',
    component: () => import('../views/ContactView.vue'),
    meta: {
        title: "Contact Us"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/LoginView.vue'),
    meta: {
      guest: true,
      title: "SignIn"
    }
  },
  {
    path: '/recover-password',
    name: 'Recover Password',
    component: () => import('../views/auth/ResetPasswordView.vue'),
    meta: {
      guest: true,
      title: "Password Recovery"
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/auth/SignupView.vue'),
    meta: {
      guest: true,
      title: "SignUp"
    }
  },
  {
    path: '/policies/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/policies/PrivacyPolicy.vue'),
    meta: {
        title: "Privacy Policy"
    }
  },
  {
    path: '/policies/terms-of-service',
    name: 'terms',
    component: () => import('../views/policies/TermsofService.vue'),
    meta: {
        title: "Terms of Service"
    }
  },
  {
    path: '/collection/:id',
    name: 'collection',
    component: () => import('../views/collection/ListView.vue'),
    meta: {
        title: "Collections"
    }
  },
  {
    path: '/policies/refund-policy',
    name: 'refund',
    component: () => import('../views/policies/RefundPolicy.vue'),
    meta: {
        title: "Refund Policy"
    }
  },
  {
    path: '/policies/shipping-policy',
    name: 'shipping',
    component: () => import('../views/policies/ShippingPolicy.vue'),
    meta: {
        title: "Shipping"
    }
  },
  {
    path: '/checkouts/information',
    name: 'checkouts',
    component: () => import('../components/Checkout/Checkout.vue'),
    props: true,
    meta: {
        title: "Cart Checkout"
    }
  },
  {
    path: '/product/:id',
    name: 'product',
    component: () => import('../components/Product.vue'),
    meta: {
        title: "Products"
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../components/Slices/Cart.vue'),
    meta: {
        title: "Cart"
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/pages/Account.vue'),
    meta: {
      auth: true,
      title: "My Account"
    }
  },
  {
    path: '/account/addresses',
    name: 'address',
    component: () => import('../views/pages/Address.vue'),
    meta: {
      auth: true,
      title: "Address Information"
    }
  },
  {
    path: '/account/profile',
    name: 'profile',
    component: () => import('../views/pages/Profile.vue'),
    meta: {
      auth: true,
      title: "My Profile"
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/pages/Search.vue'),
    meta: {
        title: "Search Products"
    }
  },
  {
    path: '/checkouts/shipping',
    name: 'delivery_method',
    component: () => import('../components/Checkout/DeliveryMethod.vue'),
    meta: {
        title: "Delivery Method"
    }
  },
  {
    path: '/checkouts/payment',
    name: 'payment',
    component: () => import('../components/Checkout/Payment.vue'),
    meta: {
        title: "Payment"
    }
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('../views/collection/Collection.vue'),
    meta: {
        title: "Collections"
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {

    document.title = to.meta?.title ? to.meta?.title + ` - FF NETWORK SDN BHD` : "Buy Best Slices at selected myNEWS Stores - FF NETWORK SDN BHD"

    if (to.matched.some(record => record.meta.auth)) {
      if (!store.getters.GET_AUTH_STATUS) {
          next({
              name: 'login'
          })
      } else {
          next()
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (store.getters.GET_AUTH_STATUS) {
          next({
              name: 'account'
          })
      } else {
          next()
      }
  } else{
      next()
  } 
})

export default router
