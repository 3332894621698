export default {
  products(state) {
    return state.products;
  },
  totalSum(state) {
    return state.total;
  },
  quantity(state) {
    return state.qty;
  },
  cart(state) {
    return state.cart;
  },
  GET_CHECKOUT_INFORMATION(state) {
    return state.data_checkout_information;
  },
};