<template>
  <section class="homepage">
    <header-prismic />
    <section class="hero-section">
      <div class="hero-inner-section">
        <div class="slider-item slider-item-img">
          <img src="/assets/image/hero-img-1.jpg" class="hero-img" alt="">
          <div class="slider-content">

            <!-- <div class="slider-flex">
              <h1 class="food-text">food foundry</h1>
              <p> x </p>
              <img src="/assets/image/mynews-logo.png" alt="">
            </div>
            <h6>Slices available for pick up at selected MyNews stores!</h6> -->

            <div class="mega-menu-item" v-for="(category, key) in categories" :key="key">
              <router-link v-if="category.name == 'Singles'" :to="{ name: 'collection', params: { id: category.id } }" class="header">Order Now!</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <cta-slice></cta-slice>
    <!-- <today-treats-slice></today-treats-slice>
    <pie-slice></pie-slice> -->
    <mille-crepes></mille-crepes>
    <footer-prismic />
  </section>
</template>

<script>
// imports for all components
import HeaderPrismic from '../components/HeaderPrismic.vue'
import FooterPrismic from '../components/FooterPrismic.vue'
import CtaSlice from '../components/Slices/CtaSlice.vue'
// import TodayTreatsSlice from '../components/Slices/TodayTreatsSlice.vue'
// import PieSlice from '../components/Slices/PiesSlice.vue'
import MilleCrepes from '../components/Slices/MilleCrepesSlice.vue'


export default {
  name: 'home-page',
  components: {
    HeaderPrismic,
    FooterPrismic,
    CtaSlice,
    // TodayTreatsSlice,
    // PieSlice,
    MilleCrepes,
  },
  data() {
    return {
      categories: [],
    }
  },
  created() {
    this.getCategory();
  },

  methods: {

    getCategory() {
        this.$store.dispatch("SHOW_CATEGORY")
          .then((response) => {
            this.categories = response.data.categories;
          }).catch((err) => {
            console.log(err);
          });
    },

  }
}
</script>

<style>
.homepage .hero-inner-section .slider-item {
  height: 100vh;
  width: 100%;
  position: relative;

}

.homepage .hero-inner-section .slider-item .hero-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage .hero-inner-section .slider-item .slider-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}

.homepage .hero-inner-section .slider-item .slider-content h6 {
  font-size: 18px;
  text-transform: uppercase;
  max-width: 670px;
  margin: 10px auto 0;
}

.homepage .hero-inner-section .slider-item .slider-content a {
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-style: var(--body-font-style);
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  padding: 10px 30px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none !important;
  letter-spacing: 0px;
  background-color: #ffffff;
  color: #000;
  border: 1px solid #ffffff;
  border-radius: 0;
  min-width: 150px;
  text-align: center;
  margin: 10px 10px 0;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  margin-top: 15px;
}

.homepage .hero-inner-section .slider-item .slider-content .slider-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage .hero-inner-section .slider-item .slider-content .slider-flex img {
  width: 200px;
  height: auto;
  position: unset !important;
}

.homepage .hero-inner-section .slider-item .slider-content .slider-flex p {
  font-size: 68px;
  margin: 0 50px;
  font-weight: 400;
  line-height: normal;
  text-shadow: 0 4px 11px rgb(62 62 62 / 70%);
  font-family: "Work Sans" !important;
  font-weight: 300 !important;
}

.homepage .food-text{
  font-family: "Work Sans" !important;
  font-weight: 300 !important;
}

.homepage .hero-inner-section .slider-item .slider-content .food-text {
  font-size: 42px;
  margin-bottom: 15px;
  font-family: "PT Sans";
  margin: 0;
  text-shadow: 0 4px 11px rgb(62 62 62 / 70%);
  font-weight: 500;
  color: #fff !important;
}

.homepage .slider-item-img{
  height: 100vh !important;
}

.homepage .hero-inner-section .slider-item .slider-content p {
  font-size: 18px;
}

@media screen and (max-width:506px)  and (min-width:390px) {
    .homepage .hero-inner-section .slider-item .slider-content .food-text {
      font-size: 32px;
    }
    .homepage .hero-inner-section .slider-item .slider-content .slider-flex p {
      font-size: 46px;
    }
    .homepage .hero-inner-section .slider-item .slider-content .slider-flex img {
      width: 127px;
    }
}

@media screen and (max-width: 768px) {
  .homepage .hero-inner-section .slider-item .slider-content .slider-flex p{
    /*margin: 0 10px*/
    margin: 0px 0px 16px 0px;
  }
}

@media screen and (max-width: 576px) {
  .homepage .hero-inner-section .slider-item .slider-content .slider-flex{
    display: block;
    flex-wrap: wrap;

  }
}
@media screen and (max-width:390px) {
  .homepage .hero-inner-section .slider-item .slider-content .slider-flex{
    display: block;
    margin: 50px 0px 40px 0px;

  }
}

@media screen and (max-width: 576px) {
  .homepage .hero-inner-section .slider-item .slider-content{
    margin-top: -123px;
  }
}

</style>