<template>
   <div class="screen" :class="{ 'd-none': this.loader }">
        <div class="loader-card" id="loading">
            <img width="200" :src="loader_image">
        </div>
    </div>
  <div class="container cart-main-section" v-if="cart_detail.length > 0">
    <h2>Shopping Cart</h2>
    <div class="row m-0">
      <div class="col-lg-9">
        <div class="ct-table-responsive">
          <table class="table" style="text-align: left;">
            <tr>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
            <tr v-for="(products, key) in cart_detail" :key="key">
              <td>
                <img :src="products.product.images[0].url" style="height:124px; width:120px;" /> {{ products.product.name
                }}
              </td>
              <td> {{ products.product.prices.currency }} {{ products.product.prices.price }} </td>
              <td>
                <div class="wrapper">
                  <div class="quantity-inner">
                    <button class="btn btn--minus" @click="minusQty(products)" type="button" name="minusButton"> -
                    </button>
                    <input class="quantity" type="text" name="name" :value="products.quantity" style="width: 12%;" />
                    <button class="btn btn--plus dd" @click="plusQty(products)" type="button" name="plusButton"> +
                    </button>
                  </div>
                  <button class="btn delete" @click="removeItem(products.id)" type="button" name="removeButton"> Remove
                  </button>
                </div>
              </td>
              <td>{{ products.product.prices.currency }} {{ products.product.prices.price * products.quantity }} </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-lg-3">
        <form action="" @submit.prevent="OrderForm">
          <div class="row m-0">
            <div class="col-12 subtotal">
              <h3><span>SubTotal : </span><span>{{ SubTotalPrice }}</span></h3>
            </div>
            <div class="col-12 tax mb-4">
              <label>Excluding taxes and shipping</label>
            </div>
            <div class="ct-form-control text-start">
              <div class="form-check text-start">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="check_term_condition"
                  @click="CheckInput" />
                <label class="form-check-label" for="flexCheckDefault"> I agree with the terms and conditions </label>
              </div>
              <label class="text-danger"> {{ error }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-between delivery mt-3">
              <button v-if="allowSelfPickup" class="btn btn-dark" @click="delivery_btn('SELFPICKUP')" :class="{ active: isActive('SELFPICKUP') }"
                :disabled="disablePickup"><svg xmlns="http://www.w3.org/2000/svg" height="30" version="1"
                  viewBox="0 0 256 256" preserveAspectRatio="">
                  <path
                    d="M34 2c-3 4-2 9 1 12 2 2 3 2 93 2s91 0 93-2c4-3 4-8 1-12l-1-2H35l-1 2zM16 55L0 87l128 1 128-1-16-32-15-32H32L16 55zm49-18l2 2-8 19c-8 15-9 17-11 17s-3-1-3-3c0-3 17-36 19-36l1 1zm32 0l2 2-8 19c-8 15-9 17-11 17s-3-1-3-3c0-3 17-36 19-36l1 1zm33 0c1 1 2 4 2 17 0 17-1 21-4 21s-4-4-4-21c0-13 1-16 2-17l2-1 2 1zm31 0l10 17c9 17 10 21 5 21a254 254 0 0 1-21-35c0-2 2-4 4-4l2 1zm33 1c3 3 17 32 17 34s-1 3-3 3-3-2-10-17c-9-17-10-20-8-21s3-1 4 1zM0 104c1 8 1 10 4 15 3 6 10 12 16 15l4 1v104H14c-9 1-10 1-12 3-3 4-3 9 1 12 2 2 2 2 125 2s123 0 125-2c4-3 4-8 1-12-2-2-3-2-12-3h-10V135l4-1c6-3 13-9 17-15 2-5 3-7 3-15v-9H0v9zm65 9c0 3 6 12 10 15l9 6 4 1v104H40V135l4-1 9-6c4-3 10-12 10-15l1-2 1 2zm132 8c3 5 9 11 15 13l4 1v104H96V136h5c10-2 20-9 24-19l3-5 3 6c3 6 9 12 16 15 3 2 6 3 13 3s10-1 13-3c7-3 13-9 17-16l2-6 2 3 3 7z">
                  </path>
                  <path
                    d="M68 160c-4 2-6 10-2 14 2 1 2 3 2 9 0 7 0 9-2 11-3 3-3 9 1 12 5 4 13 1 13-6l-2-6c-2-2-2-4-2-11 0-6 0-8 2-10 3-2 3-8 0-11-2-2-7-3-10-2zM145 162l-11 11 3 3 3 3 12-11 11-12-3-3-3-3-12 12zM157 173l-23 23 3 3 4 3 23-23 22-23-2-3-4-3-23 23zM168 183l-11 13 3 3 3 3 11-12 12-12-3-3-4-3-11 11z">
                  </path>
                </svg><span>Self Pickup</span>
              </button>

              <button v-if="allowDelivery" class="btn btn-dark" @click="delivery_btn('Local_Delivery')"
                :class="{ active: isActive('Local_Delivery') }" :disabled="disableDelivery">
                <svg xmlns="http://www.w3.org/2000/svg" height="30" version="1" viewBox="0 0 160 128"
                  preserveAspectRatio="">
                  <path
                    d="M23 3l-1 8v7l-10 1-11 3c-3 2 0 4 7 5l8 2-8 2c-6 1-7 2-8 4 0 2 1 2 7 3l8 2-6 1c-8 1-9 2-9 4s1 2 6 3l11 2 5 1v11l1 12 68 1h67V59l-11-21-10-21-5-1h-4v10h-12V16l-1-12c0-2-5-2-46-2L23 3zm113 32l3 7 8 19h-27V34h8l8 1zM23 83l-1 13v12h14l1-4c3-5 7-10 11-11 7-2 17 0 21 5l4 5 1 5h20c19 0 20-1 21-2 6-11 10-14 18-14 9 0 15 4 18 12 2 3 2 4 4 4 2-1 2-1 2-14V83l-67-1c-60 0-67 0-67 2z">
                  </path>
                  <path
                    d="M50 100c-6 3-9 9-7 15s6 9 12 9c9 0 13-5 13-13 0-10-10-16-18-11zm77 0c-6 4-8 12-5 18 3 4 6 6 12 6 5 0 6 0 9-3 4-5 5-10 2-15-3-7-11-10-18-6z">
                  </path>
                </svg>
                <span>Local Delivery</span>
              </button>
            </div>






            <!-- date section : Self_Pickup :start -->
            <div class="mt-2 mb-2" v-if="is_show_date">
              <h6 class="text-start">Please select a pickup location:</h6>
              <div class="mb-3">
                <div class="pickup-location text-start" v-for="(address, key) in addresses" :key="key">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="address" :id="address.id" :value="address.id"
                      v-model="selected_address" required />
                    <label class="form-check-label" :for="address.id">
                      <!-- <b>{{ address.name }}</b><br />{{ address.address }} -->
                      <b>{{ address.building }}</b><br />{{ address.map_address }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 date-input">
                <input type="date" v-model="date_pickup" :min="minDate" class="w-100" onkeydown="return false" required />
                <span>
                  <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z">
                    </path>
                  </svg>
                </span>
              </div><br>
              <div class="col-12 my-3 date-input">
                <!-- <input type="time" v-model="time_pickup" class="w-100" required /> -->
                <span>Your order will be ready to collect 9AM onwards.</span>
                <!-- <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" xmlns:xlink="http://www.w3.org/1999/xlink"
                    fill="#000000" version="1.1" viewBox="0 0 203.543 203.543"
                    enable-background="new 0 0 203.543 203.543">
                    <g>
                      <path
                        d="m194.139,11.918h-39.315v-5.918c0-3.313-2.687-6-6-6s-6,2.687-6,6v5.918h-35.053v-5.918c0-3.313-2.687-6-6-6s-6,2.687-6,6v5.918h-33.563v-5.918c0-3.313-2.687-6-6-6s-6,2.687-6,6v5.918h-40.804c-3.313,0-6,2.687-6,6v135.572c0,3.313 2.687,6 6,6h36.826c5.901,25.214 28.555,44.053 55.541,44.053s49.64-18.84 55.541-44.053h36.826c3.313,0 6-2.687 6-6v-135.572c0.001-3.313-2.685-6-5.999-6zm-143.931,12v5.422c0,3.313 2.687,6 6,6s6-2.687 6-6v-5.422h33.563v5.422c0,3.313 2.687,6 6,6s6-2.687 6-6v-5.422h35.053v5.422c0,3.313 2.687,6 6,6s6-2.687 6-6v-5.422h33.315v24.536h-172.735v-24.536h34.804zm51.563,167.625c-24.842,0-45.053-20.211-45.053-45.053s20.211-45.053 45.053-45.053 45.053,20.21 45.053,45.053-20.21,45.053-45.053,45.053zm57.028-44.053c0.006-0.334 0.025-0.665 0.025-1 0-31.459-25.594-57.053-57.053-57.053s-57.053,25.594-57.053,57.053c0,0.335 0.02,0.666 0.025,1h-29.34v-87.035h172.735v87.035h-29.339z" />
                      <path
                        d="m107.771,148.004v-29.026c0-3.313-2.687-6-6-6s-6,2.687-6,6v31.512c0,1.591 0.632,3.117 1.757,4.243l13.79,13.791c1.172,1.171 2.707,1.757 4.243,1.757 1.535,0 3.071-0.586 4.243-1.757 2.343-2.343 2.343-6.142 0-8.485l-12.033-12.035z" />
                    </g>
                  </svg>
                </span> -->
              </div>
            </div>
            <!-- date section : Self_Pickup :end -->
            <span class="text-danger danger-delivery mt-3" v-if="!is_available">{{ error_deliver }}</span>
            <div class=" mt-3 text-start" v-if="is_available">
              <span class="danger-delivery">When would you like to have your order delivered?</span>
              <div class="col-12 my-3 date-input">
                <input type="date" name="name" :min="minDate" class="w-100" v-model="date" @change="changeDate()" />
                <span>
                  <svg height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 20h-4v-4h4v4zm-6-10h-4v4h4v-4zm6 0h-4v4h4v-4zm-12 6h-4v4h4v-4zm6 0h-4v4h4v-4zm-6-6h-4v4h4v-4zm16-8v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z">
                    </path>
                  </svg>
                </span>
              </div>
              <div class="">
                <div v-if="time.length > 0">
                  <div class="d-flex" v-for="(item, key) in time" :key="key">
                    <input class="me-2" type="radio" name="time" v-model="select_time" :value="item" :id="key" />
                    <label :for="key">{{ item }}</label>
                  </div>
                </div>
                <div v-else>
                  <span> No deliveries on this day, Please select another date.</span>
                </div>
              </div>
              <div class="mt-2" v-if="time.length > 0">
                <label class="form-label right_side"> Please enter delivery Address</label>
                <textarea class="w-100" cols="40" rows="3" v-model="selected_delivery_address"></textarea>
              </div>
              <div class="mt-2" v-if="time.length > 0">
                <label class="form-label right_side"> Please enter delivery instructions (optional)</label>
                <textarea class="w-100" cols="40" rows="3" v-model="deliver_instruction"></textarea>
              </div>
            </div>
            <div class="button_checkout">
              <div class="col-12 mt-3">
                <button class="btn checkout-button w-100" @click="verifyInput()">CHECKOUT</button>
              </div>
              <div class="col-12 mt-3">
                <button class="btn continue-button w-100" type="button" @click="back_home()">CONTINUE SHOPPING</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="container cart-slice" v-else>
    <div class="row">
      <div class="col-12">
        <h1 class="shopping_cart">Shopping Cart</h1>
        <h4 class="cart_empty">Your cart is empty</h4>
        <button class="btn_cart_empty mt-3" @click="back_home()">CONTINUE SHOPPING</button>
      </div>
    </div>
  </div>

  <!-- <div v-if="gkash != ['']"> -->
    <!-- <form method= "post" ref="GkashForm" action="https://api-staging.pay.asia/api/paymentform.aspx" > -->
    <form method= "post" ref="GkashForm" action="https://api.gkash.my/api/paymentform.aspx" >
      <input ref="gkash-version" name= "version" type= "hidden" v-model="gkash.version" >
      <input ref="gkash-CID" name= "CID" type= "hidden" v-model="gkash.CID" >
      <input ref="gkash-v_currency" name= "v_currency" type= "hidden" v-model="gkash.v_currency" >
      <input ref="gkash-v_amount" name= "v_amount" type= "hidden" v-model="gkash.v_amount" >
      <input ref="gkash-v_cartid" name= "v_cartid" type= "hidden" v-model="gkash.v_cartid" >
      <input ref="gkash-signature" name= "signature" type= "hidden" v-model="gkash.signature" >
      <input ref="gkash-returnurl" name= "returnurl" type= "hidden" v-model="gkash.returnurl" >
      <input ref="gkash-callbackurl" name= "callbackurl" type= "hidden" v-model="gkash.callbackurl" >
      <!-- <input type= "submit" value= "Submit" > -->
    </form >
  <!-- </div> -->

  <!-- order model :start -->
  <!-- <div class="cart-bar-order" :class="{ 'd-none': isActiveOrderModal }"> -->
    <div class="cart-bar-order" :class="{ 'd-none': true }">
    <!-- <div class="cart-bar-order"> -->
    <div class="modal-inner-bar-order">
      <div class="container">
        <form @submit.prevent="validate">
          <div class="form-group">
            <label>Card Number:</label>
            <div class="input-group mb-0">
              <input ref="cardNumInput" :data-error="(cardErrors.card_number)?true:false" v-model="card_number" type="tel" class="form-control" placeholder="#### #### #### ####" v-cardformat:formatCardNumber required>
            </div>
            <div v-if="cardErrors.card_number" class="error">
              <small class="text-danger"> {{ cardErrors.card_number }}</small>
            </div>
          </div>
          <div class="form-group">
            <label>Card Expiration:</label>
            <input ref="cardExpInput" id="card-exp" :data-error="(cardErrors.card_exp_date)?true:false" v-model="card_exp_date" maxlength="10" class="form-control" v-cardformat:formatCardExpiry>
            <div v-if="cardErrors.card_exp_date" class="error">
              <small class="text-danger">{{ cardErrors.card_exp_date }}</small>
            </div>
          </div>
          <div class="form-group">
            <label>Card CVC:</label>
            <input ref="cardCvcInput" :data-error="(cardErrors.card_csv)?true:false" v-model="card_csv" class="form-control" v-cardformat:formatCardCVC>
            <div v-if="cardErrors.card_csv" class="error">
              <small class="text-danger">{{ cardErrors.card_csv }}</small>
            </div>
          </div>
          <button class="btn ct-submit-button" type="submit">Pay Now</button>
          <div class="row search-item-loader px-2">
            <div class="col-12">
              <div class="changingCssAnimation" :class="{ 'd-none': is_hide_loader_order }">
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <button class="btn order-close-button" @click="closeEvent()">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"
          class="icon icon-close">
          <path
            d="M446.174 512l-432.542-432.542c-18.177-18.177-18.177-47.649 0-65.826s47.649-18.177 65.826 0l432.542 432.542 432.542-432.542c18.177-18.177 47.649-18.177 65.826 0s18.177 47.649 0 65.826l-432.542 432.542 432.542 432.542c18.177 18.177 18.177 47.649 0 65.826s-47.649 18.177-65.826 0l-432.542-432.542-432.542 432.542c-18.177 18.177-47.649 18.177-65.826 0s-18.177-47.649 0-65.826l432.542-432.542z">
          </path>
        </svg>
      </button>
    </div>
  </div>

  <!-- order model :end -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment'
import { product_record } from '../../constant.js'
export default {
  name: 'cart',
  data() {
    return {
      check_term_condition: false,
      cardErrors: {},
      checkout_method: null,
      date: null,
      time: [],
      select_time: '',
      is_available: false,
      error_deliver: "Sorry, an item in your cart is not currently available for pickup.",
      deliver_instruction: null,
      selected_delivery_address: null,
      error: null,
      cart_detail: [],
      date_pickup: new Date().toISOString().slice(0, 10),
      time_pickup: null,
      is_show_date: false,
      qty_error: true,
      addresses: product_record.address,
      selected_address: '',
      datetime: null,
      isActiveOrderModal: true,
      card_number: null,
      exp_month: null,
      exp_year: null,
      card_csv: null,
      is_hide_loader_order: true,
      card_exp_date: null,
      gkash: [],
      minDate:'',
      allowSelfPickup: '',
      allowDelivery: '',
      disablePickup: true,
      disableDelivery: true,
      loader:true,
    }
  },
  mounted() {
    this.getCartDetails();
    this.setMinDate();
  },
  computed: {
    SubTotalPrice() {
      var subTotal = 0;
      for (var i = 0; i < this.cart_detail.length; i++) {
        subTotal += this.cart_detail[i].quantity * this.cart_detail[i].product.prices.price;
      }
      return subTotal.toFixed(2);
    },
    loader_image() {
          return '/assets/image/loader.png';
      },
  },

  methods: {
    back_home(){
      if(this.$route.name == 'home'){
        document.querySelector(".cart-close-button").click();
      }else{
        this.$router.push({
          name: 'home',
        })
      }
    },
    setMinDate() {
      var time = moment().format("HH");

      if(time < 14){
        this.minDate = moment().add(2, 'days').format("YYYY-MM-DD");
        this.date_pickup = moment().add(2, 'days').format("YYYY-MM-DD");
        this.date = moment().add(2, 'days').format("YYYY-MM-DD");
      }else{
        this.minDate = moment().add(3, 'days').format("YYYY-MM-DD");
        this.date_pickup = moment().add(3, 'days').format("YYYY-MM-DD");
        this.date = moment().add(3, 'days').format("YYYY-MM-DD");
      }

      var day = moment(this.minDate).format('dddd')
      console.log(day)
      if(day == 'Sunday'){
        this.time = [];
      }else if(day == 'Saturday'){
        this.time = ['11:00 AM - 12:00 PM'];
      }else{
        this.time = ['10:00 AM - 12:00 PM'];
      }

    },
    closeEvent() {
      this.isActiveOrderModal = !this.isActiveOrderModal
    },
    validate: function(){
      // init
      this.cardErrors = {};

      // validate card number
      if(!this.$cardFormat.validateCardNumber(this.card_number)){
        this.cardErrors.card_number = "Invalid Credit Card Number.";
      }

     // validate card expiry
     if (!this.$cardFormat.validateCardExpiry(this.card_exp_date)) {
        this.cardErrors.card_exp_date = "Invalid Expiration Date.";
      }

      // validate card CVC
      if (!this.$cardFormat.validateCardCVC(this.card_csv)) {
        this.cardErrors.card_csv = "Invalid CVC.";
      }

      console.log("error",Object.keys(this.cardErrors).length);
      if(Object.keys(this.cardErrors).length == 0){
        this.OrderForm();
      }
    },
    isActive: function (type) {
      return this.checkout_method == type;
    },
    minusQty: function (products) {
      products.quantity -= 1;
      if (products.quantity < 0) {
        products.quantity = 0;
      }

      let data = new FormData();
      data.append('product_id', products.product.id);
      data.append('quantity', products.quantity);
      data.append('cart_id', products.id);

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.GET_AUTH_TOKEN;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      axios.post(process.env.VUE_APP_BASE_URL + '/cart', data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(response => {
        this.cart_detail = response.data.carts;

        let list = [];
        $.each(response.data.carts, function (key, value) {
          if (value.id == products.id) {
            list.push(value.product.max_quantity);
          }
        });

        if (products.quantity > list[0]) {
          this.qty_error = false;
        } else {
          this.qty_error = true;
        }

        this.getCartDetails();

      }).catch(err => {
        console.log("err", err);
      })
      // products.total = products.quantity * products.product.prices.price;
    },
    plusQty: function (products) {
      products.quantity += 1;

      let data = new FormData();
      data.append('product_id', products.product.id);
      data.append('quantity', products.quantity);
      data.append('cart_id', products.id);

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.GET_AUTH_TOKEN;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      axios.post(process.env.VUE_APP_BASE_URL + '/cart', data, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      }).then(response => {

        this.cart_detail = response.data.carts;
        let list = [];
        $.each(response.data.carts, function (key, value) {
          if (value.id == products.id) {
            list.push(value.product.max_quantity);
          }
        });
        if (products.quantity > list[0]) {
          this.qty_error = false;
          this.$toast.error("Sorry, looks like we don't have enough of this product.");
        } else {
          this.qty_error = true;
        }
      }).catch(err => {
        console.log("err", err);
      })
    },
    removeItem: function (id) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.GET_AUTH_TOKEN;
      axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

      if (confirm("Are you sure you want to delete product from cart?")) {
        axios.delete(process.env.VUE_APP_BASE_URL + '/cart/' + id, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        }).then(response => {
          this.$toast.success(response.data.message);
          this.getCartDetails();
        }).catch(err => {
          // this.$toast.error(err.response.data.message);
          console.log("error", err);
        })
      }
    },
    delivery_btn: function (type) {

      event.preventDefault();

      this.checkout_method = type;
      if (type == 'Local_Delivery') {
        this.error_deliver = "Sorry, an item in your cart is not currently available for pickup.";
        this.is_available = true;
        // this.time = [];
        this.is_show_date = false
      } else {
        console.log('hi')
        this.is_available = false;
        this.error_deliver = "";
        this.time = [];
        this.date = null;
        this.is_show_date = true
      }
    },
    verifyInput: function () {

      event.preventDefault()

      if (this.checkout_method == 'Local_Delivery'){

        if(this.check_term_condition && this.date && this.select_time && this.selected_delivery_address){
          this.OrderForm()
        }else if(!this.check_term_condition){
          this.$toast.error('terms and condition required');
        }else if(!this.date){
          this.$toast.error('delivery date required');
        }else if(!this.select_time){
          this.$toast.error('delivery time slot required');
        }else if(!this.selected_delivery_address){
          this.$toast.error('delivery address required');
        }

      }else{

        if(this.check_term_condition && this.selected_address && this.date_pickup){
          this.OrderForm()
        }else if(!this.check_term_condition){
          this.$toast.error('terms and condition required');
        }else if(!this.selected_address){
          this.$toast.error('Pickup location required');
        }else if(!this.date_pickup){
          this.$toast.error('Pickup date required');
        }
      }

    },
    changeDate: function () {

      var day = moment(this.date).format('dddd')

      if(day == 'Sunday'){
        this.time = [];
      }else if(day == 'Saturday'){
        this.time = ['11:00 AM - 12:00 PM'];
      }else{
        this.time = ['10:00 AM - 12:00 PM'];
      }

      console.log("call here "+day);
    },
    CheckoutModalForm: function () {
      console.log("call here--->");

      // this.OrderForm();

      // this.$refs.GkashForm.submit();

      // this.isActiveOrderModal = !this.isActiveOrderModal

      // var version= "1.5.5";
      // var v_currency= "MYR";
      // var v_amount= "15";
      // // var v_firstname= "Arockia Doss";
      // // var v_billemail= "arockiadoss.wefour@gmail.com";
      // // var v_billphone= "6065564555";
      // var CID= "M161-U-40701";
      // var signature= "e3db25e5e5700099b8fbca9f90fcf3b5262eee2322924643742de7c84df7373f9cdf9f19d891c21bfb1186a35f9b6ef0f54c232eae349f11beae94439de383c9";
      // var v_cartid= "20230413073050";
      // var returnurl= "https://dxpro.asia/dashboard";
      // // var callbackurl= "https://dxpro.asia/payments";

      // location.href="https://api-staging.pay.asia/api/PaymentForm.aspx?version="+ version +"&v_currency="+ v_currency +"&v_amount="+ v_amount +"&v_cartid="+ v_cartid +"&signature="+ signature +"&CID="+ CID +"&returnurl="+ returnurl;

      // const cart_data = {
      //   date: this.date_pickup,
      //   time: this.time_pickup,
      //   address: this.selected_address,
      //   delivery_method: this.checkout_method
      // };

      // if (this.qty_error == false) {
      //   this.$toast.error("please remove Qty from Cart");
      // } else if (this.checkout_method != "Local_Delivery") {
      //   this.$store.dispatch("ODER_DATE_TIME", cart_data);
      //   this.$router.push('/checkouts/information');
      // }
    },
    CheckInput() {
      if (this.check_term_condition) {
        this.error = "You must accept our terms and conditions.";
      } else {
        this.error = '';
      }
    },
    getCartDetails() {

      if (this.$store.getters.GET_AUTH_STATUS) {

        this.$store.dispatch("SHOW_CART_DETAILS")
          .then((response) => {
            console.log("RESPONSE_CART_MODEL----->", response.data.carts);

            this.cart_detail = response.data.carts;
            this.addresses = response.data.shop_addresses

            var service_arr = []

            for(var i = 0; i<this.cart_detail.length; i++){
              service_arr.push(this.cart_detail[i].product.delivery_type)
            }

            console.log(service_arr);
            if(service_arr.includes('LOCALDELIVERY')){
              this.allowSelfPickup = false
              this.allowDelivery = true
              this.delivery_btn('Local_Delivery')

            }else{

              this.allowSelfPickup = true
              this.allowDelivery = false
              this.delivery_btn('SELFPICKUP')
            }

            // this.gkash=response.data.gkashInput;
            // console.log("RESPONSE_Gkash_MODEL----->", this.gkash);


          }).catch((err) => {
            console.log("un", err)
            // this.$toast.error(err.response.data.message);
          });
      }
    },
    OrderForm() {
      this.is_hide_loader_order = false;
      this.loader = false;

      this.isActiveOrderModal = !this.isActiveOrderModal
      var order_data = ''
      // var card_date = this.card_exp_date.split("/");
      // var month = parseInt(card_date[0], 10);
      // var year = card_date[1];

      if(this.checkout_method == 'Local_Delivery'){

        var sel_time = ''

        if(this.select_time == '11:00 AM - 12:00 PM'){
          sel_time = '11:00';
        }else if(this.select_time == '10:00 AM - 12:00 PM'){
          sel_time = '10:00';
        }

        order_data = {
          delivery_type: this.checkout_method,
          shop_address_id: 0,
          delivery_date: this.date +' '+sel_time,
          time_between: '5 to 6',
          note: 'nil',
          payment_mode: 'gkash',
          delivery_address: this.selected_delivery_address,

          // number: this.card_number.split(' ').join(''),
          // exp_month: month,
          // exp_year: year,
          // cvc: this.card_csv,
        }

      }else{

        order_data = {
          delivery_type: this.checkout_method,
          shop_address_id: this.selected_address,
          delivery_date: this.date_pickup + ' 09:00',
          time_between: '5 to 6',
          note: 'nil',
          payment_mode: 'gkash',
          delivery_address:'',

          // number: this.card_number.split(' ').join(''),
          // exp_month: month,
          // exp_year: year,
          // cvc: this.card_csv,
        }

      }
      this.$store.dispatch("STORE_ORDER_DETAILS", order_data)
        .then((response) => {
          console.log("STORE_ORDER_DETAILS RESPONSE----------->", response);
          this.is_hide_loader = true;
            this.loader = false;

          this.closeEvent();

          // console.log("Gkash RESPONSE----------->", response.data.gkashData);

          this.gkash.signature = response.data.gkashData.signature;
          this.gkash.CID = response.data.gkashData.CID;
          this.gkash.v_amount = response.data.gkashData.v_amount;
          this.gkash.v_cartid = response.data.gkashData.v_cartid;
          this.gkash.v_currency = response.data.gkashData.v_currency;
          this.gkash.version = response.data.gkashData.version;
          this.gkash.callbackurl = response.data.gkashData.callbackurl;
          this.gkash.returnurl = response.data.gkashData.returnurl;

          this.$nextTick(() => {
            this.$refs.GkashForm.submit();
          });

           // this.$refs.gkash-version = this.gkash.version;
           // this.$refs.gkash-version = this.gkash.version;
           // this.$refs.gkash-version = this.gkash.version;



          // if (this.gkash.version != '' && this.gkash.CID != '' && this.gkash.v_amount != ''  && this.gkash.v_cartid != '' && this.gkash.v_currency != '' && this.gkash.signature != '' && this.gkash.callbackurl != ''  && this.gkash.returnurl != '') {

              // console.log("Gkash RESPONSE----------->", this.gkash);

              // this.$refs.GkashForm.submit();
          // }

          // this.$refs.GkashForm.submit();

          // console.log('My Gkash', response.data.gkashData.v_amount);

          // if (response.data.invoice.payment_id != null) {
          //   this.$router.push({
          //     name: 'account',
          //   })
          // } else {
          //   this.$toast.error("Payment Unsuccessful");
          // }

        }).catch((err) => {
          this.is_hide_loader = true;
          console.log(err.response);
          this.$toast.error(err.response.data.error);
        });
    },
  },
  watch: {

    // card_number: function(val){
    //   if(this.$cardFormat.validateCardNumber(val)){
    //     this.$refs.cardExpInput.focus();
    //   }
    // },
    // card_exp_date: function (val) {
    //   if (this.$cardFormat.validateCardExpiry(val)) {
    //     this.$refs.cardCvcInput.focus();
    //   }
    // }
    check_term_condition: function(val){

      if(val == true){

        if(this.allowSelfPickup == true){

          this.delivery_btn('SELFPICKUP')
          // this.disablePickup = false;

        }else if(this.allowDelivery == true){
          // this.disableDelivery = false;
          this.delivery_btn('Local_Delivery')

        }

      }else if(val == false){
          this.disablePickup = true;
          this.disableDelivery = true;
      }

    },

  }
}
</script>

<style>
.screen {
    z-index: 9999;
    display: flex;
    align-items: center;
    background-color: #ffffff29;
    backdrop-filter: blur(4px);
    position: absolute;
    width: 100%;
    height: 100%;
}
.loader-card {
    width: 100%;
    text-align: center;
}
.btn_cart_empty {
  display: inline-block;
  position: relative;
  font-weight: 400;
  font-size: 19px;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  padding: 11px 30px;
  margin: 0 auto;
  line-height: 23px;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none !important;
  letter-spacing: 0px;
  background-color: #ffffff;
  color: #000;
  border: 1px solid #000;
  border-radius: 0;
  width: 491px;
  height: 47px;
  background: white;
}

.btn_cart_empty:hover {
  color: black !important;
}

.cart_empty {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  font-style: var(--body-font-style);
  letter-spacing: var(--body-letter-spacing);
  font-size: 23px;
  margin: 0 0 17px;
}

.shopping_cart {
  font-family: var(--body-font-family);
  font-weight: var(--body-font-weight);
  font-style: var(--body-font-style);
  font-size: 3rem;
  line-height: normal;
  margin-bottom: 7px;
}

.modal-inner-bar-order .form-group input.form-control {
  border: 1px solid !important;
}

.quantity {
  text-align: center;
}

.subtotal,
.tax,
.delivery,
.danger-delivery,
.right_side {
  text-align: left;
}

.btn.delete {
  border: none;
  font-size: .86rem;
  opacity: .5;
  color: #222;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  transition: opacity .2s;
  padding: 8px 25px;
  width: 100%;
  max-width: 130px;
}

.btn.delete:hover {
  opacity: 1;
}

.quantity-inner {
  border: 1px solid #e4e4e4;
  max-width: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.quantity-inner input.quantity {
  border-radius: 0;
  border: none;
}

.delivery button.btn.btn-dark {
  align-items: center;
  border: 1px solid #dadada;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5em;
  padding: 20px 7px;
  background-color: transparent;
  font-size: 12px;
  color: #222;
  height: 100%;
  width: 100%;
  transition: all 0.6s ease;
}

.delivery button.btn.btn-dark:hover,
.delivery button.btn.btn-dark.active {
  background-color: #eee;
}

.delivery button.btn.btn-dark:disabled {
  opacity: 1;
}

.delivery .col-6 {
  padding: 0 5px;
}

.delivery button.btn.btn-dark svg {
  fill: #000;
  opacity: 1;
  margin-bottom: 5px;
}

.subtotal label {
  font-size: 20px;
  font-weight: 500;
}

.cart-bar-order .modal-inner-bar-order {
  overflow-y: auto;
  display: block;
}

.cart-main-section .table tr th {
  color: rgba(34, 34, 34, 0.6);
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;
  text-align: center;
  padding: 5px 0;
}

.cart-main-section .table tr th:first-child {
  text-align: left;
}

.cart-main-section .table tr th:last-child {
  text-align: right;
}

.cart-main-section {
  font-size: 14px;
}

.cart-main-section .table tr td {
  padding: 20px 0;
}

.subtotal h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.button_checkout button.btn {
  height: 42px;
  padding: 9px 2px;
  border-radius: 0;
  font-size: 14px;
  color: #000;
  border: 1px solid #000;
  transition: all 0.6s ease;
}

.button_checkout button.btn:disabled {
  opacity: 1;
}

.button_checkout button.btn.continue-button {
  background-color: #000;
  color: #fff;
}

.button_checkout button.btn:hover {
  opacity: 0.8;
}

.date-input {
  position: relative;
}

.date-input input {
  border: 1px solid #e4e4e4;
  border-radius: 4px 0 0 4px;
  padding: 0.7em 1em;
  position: relative;
}

.date-input span {
  position: absolute;
  top: 50%;
  right: 1px;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 9px 14px;
  pointer-events: none;
}

.cart-bar-order.active .modal-inner-bar-order {
  padding-top: 50px !important;
}

.cart-bar-order.active .modal-inner-bar-order .container {
  height: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px 10px;
}

.cart-bar-order.active .modal-inner-bar-order .container>h2 {
  font-size: 42px;
  color: #2e3234;
  font-weight: 400;
  margin-bottom: 10px;
}

.pickup-location {
  align-items: flex-start;
  border: 1px solid #dadada;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5em;
  padding: 15px;
  background-color: transparent;
  font-size: 12px;
  color: #222;
  height: 100%;
  width: 100%;
  transition: all 0.6s ease;
  margin: 10px 0;
}

.pickup-location input[type="radio"] {
  width: 14px;
  height: 14px;
}

.pickup-location input:checked[type=radio] {
  background-color: transparent;
  background-image: url(../../../public/assets/image/label_horizontal.png);
}

.pickup-location label.form-check-label {
  margin-left: 5px;
}

.pickup-location:hover,
.pickup-location.active {
  background-color: #eee;
}

.order-close-button {
  position: absolute;
  right: 30px;
}

.changingCssAnimation {
  text-align: center;
  transition: opacity 1s ease-out;
  opacity: 1
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:first-child {
  left: 8px;
  -webkit-animation: lds-ellipsis1 .6s infinite;
  animation: lds-ellipsis1 .6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
}

.lds-ellipsis div:nth-child(2),
.lds-ellipsis div:nth-child(3) {
  -webkit-animation: lds-ellipsis2 .6s infinite;
  animation: lds-ellipsis2 .6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 .6s infinite;
  animation: lds-ellipsis3 .6s infinite;
}

.cart-bar-order {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  padding: 0 15px;
}

.cart-bar-order:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.cart-bar-order .modal-inner-bar-order {
  max-height: 430px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px;
}

.cart-bar-order .form-group {
  display: block !important;
  text-align: left;
  margin: 20px 0;
}

.cart-bar-order .form-group label {
  margin-bottom: 10px;
}

.cart-bar-order .form-group input.form-control {
  padding: 6px 15px;
  min-height: fit-content;
}

.cart-bar-order .modal-inner-bar-order .ct-submit-button {
  border-radius: 0;
  background-color: #000;
  color: #fff;
  padding: 9px 30px;
  transition: all 0.6s ease;
  border: 1px solid #000;
}

.cart-bar-order .modal-inner-bar-order .ct-submit-button:hover {
  background-color: transparent;
  color: #000;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  to {
    -webkit-transform: translate(24px);
    transform: translate(24px);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  to {
    -webkit-transform: translate(24px);
    transform: translate(24px);
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cart-bar-order .modal-inner-bar-order .order-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 0;
}

.cart-bar-order .modal-inner-bar-order .order-close-button svg.icon.icon-close {
  width: 12px;
}



@media(max-width: 767px) {

  .cart-main-section .table tr:first-child {
    display: none;
  }

  .cart-main-section .table tr td {
    padding: 0;
    display: block;
    text-align: center;
  }

  .cart-main-section .table tr td img {
    display: block;
    margin: 0 auto;
  }

  .quantity-inner {
    margin: 0 auto;
  }

  .cart-bar-order.active .modal-inner-bar-order .container {
    display: block;
  }

  .cart-bar-order .modal-inner-bar-order {
    padding: 40px 15px;
  }
}

@media(max-width: 520px) {
  .cart-main-section .ct-table-responsive {
    padding-bottom: 20px;
  }

  .container.cart-slice {
    /*padding-left: 12px;*/
    /*margin-left: auto;*/

    margin: 0 auto;
    width: 100%;
  }

  .btn_cart_empty{
    width: 100%;
  }
}
</style>