import axios from "axios";
export default {
  addToCart(context, payload) {
    const prodId = payload.id;
    const products = context.rootGetters.products;
    const product = products.find((prod) => prod.id === prodId);
    context.commit("addProductToCart", product);
  },
  removeFromCart(context, payload) {
    context.commit("removeProductFromCart", payload);
  },

  // SHOW_CART_DETAILS(context) {
  //   axios.defaults.headers.common['Authorization'] = 'Bearer '+ context.state.auth_token;
  //   return new Promise((resolve, reject) => {
  //       axios.get('http://139.59.245.12/api/cart', {
  //             headers: { 'X-Requested-With': 'XMLHttpRequest' }
  //           })
  //           .then((res) => {
  //               resolve(res)
  //           }).catch((err) => {
  //               reject(err)
  //           });
  //   })
  // },

  CHECKOUT_INFORMATION(context, state) {
    context.commit('SET_CHECKOUT_INFORMATION', state)
  },

  SHOW_CATEGORY() {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_BASE_URL + '/categories?shop=' + 1, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        });
    })
  },

  SHOW_RECOMMENDED_PRODUCTS() {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_BASE_URL + '/recommended-products', {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        });
    })
  },

};