export default {
  addProductToCart(state, payload) {
    const productData = payload;
    const productInCartIndex = state.cart.findIndex(
      (ci) => ci.id === productData.id
    );
    if (productInCartIndex >= 0) {
      state.cart[productInCartIndex].qty++;
    } else {
      const newItem = {
        id: productData.id,
        type: productData.type,
        brand: productData.brand,
        model: productData.model,
        color: productData.color,
        capacity: productData.capacity,
        imgSrc: productData.imgSrc,
        price: productData.price,
        qty: 1,
      };
      state.cart.push(newItem);
    }
    state.qty++;
    state.total += productData.price;
  },

  removeProductFromCart(state, payload) {
    const prodId = payload.id;
    const productInCartIndex = state.cart.findIndex(
      (cartItem) => cartItem.id === prodId
    );
    const prodData = state.cart[productInCartIndex];
    state.cart.splice(productInCartIndex, 1);
    state.qty -= prodData.qty;
    state.total -= prodData.price * prodData.qty;
  },

  SET_CHECKOUT_INFORMATION(state,info){
    state.data_checkout_information.email=info.email;
    state.data_checkout_information.first_name=info.first_name;
    state.data_checkout_information.last_name=info.last_name;
    state.data_checkout_information.address=info.address;
    state.data_checkout_information.pincode=info.pincode;
    state.data_checkout_information.city=info.city;
    state.data_checkout_information.state=info.state;
    state.data_checkout_information.country=info.country;
    state.data_checkout_information.type=info.type;
    state.data_checkout_information.phone_number=info.phone_number;
    state.data_checkout_information.company=info.company;
    state.data_checkout_information.delivery_instruction=info.delivery_instruction;
},
};