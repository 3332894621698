<template>
  <div>
    <header class="main-header web-header">
      <div class="main-inner-header">

        <!-- <div class="header-top">
          <p>
            <a href="#">Subscribe</a> our newsletter to enjoy goodies &amp; treats!
          </p>
        </div> -->

        <div v-bind:class="'header-botton ' + [isSticky ? stickyClass : '']">
          <div class="container">
            <nav class="navbar navbar-expand-lg">
              <div class="mobile-menu-icon">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation" id="navMobile">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div class="collapse navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                  <li id="has-megamenu" class="nav-item dropdown has-megamenu">
                    <router-link to="#" class="menu-maintitle nav-link nav-link dropdown-toggle"
                      data-bs-toggle="dropdown">Shop</router-link>
                    <div class="dropdown-menu megamenu" role="menu">
                      <div class="inner-megamenu">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="mega-menu-img">
                              <img src="/assets/image/mega-menu-img-1.png" alt="" style="width:350px;height:350px;">
                              <h4>
                                <span><a href="/products/coconut-raspberry">Cake of the day</a></span>
                              </h4>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="mega-menu-main">
                              <div class="mega-menu-item" v-for="(category, key) in categories" :key="key">
                                <router-link @click="removeClassHover()" :to="{ name: 'collection', params: { id: category.id } }" class="header">{{ category.name }}</router-link>
                                <ul class="m-0 p-0">
                                  <li @click="removeClassHover()"  v-for="(product, key_product) in category.products" :key="key_product">
                                      <router-link :to="'/product/' + product.id">
                                        {{ product.name }}</router-link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link to="/about" class="menu-maintitle nav-link">About</router-link>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link to="/faqs" class="menu-maintitle nav-link">FAQs</router-link>
                  </li>
                  <li class="nav-item dropdown">
                    <router-link to="/contact" class="menu-maintitle nav-link">Contact</router-link>
                  </li>
                </ul>
              </div>
              <router-link to="/" class="logo">
                <img src="/assets/image/site-white-logo.png" alt="Site Logo" class="normal-logo" />
                <img src="/assets/image/site-logo-blue.png" alt="Site Logo" class="sticky-logo" />
              </router-link>
              <div class="right-panel">
                <router-link to="/login" v-if="!isLoggedIn">
                  <div class="account">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024"
                      class="icon icon-user">
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M775.083 319.747c0 145.297-117.786 263.083-263.083 263.083s-263.083-117.786-263.083-263.083c0-145.297 117.786-263.083 263.083-263.083s263.083 117.786 263.083 263.083z">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M208.443 542.356c-107.931 94.44-161.897 236.1-161.897 424.98">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M815.557 542.356c107.931 94.44 161.897 236.1 161.897 424.98">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000" d="M46.545 967.336h930.909"></path>
                    </svg>
                  </div>
                </router-link>
                <router-link to="/account" v-else>
                  <div class="account">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024"
                      class="icon icon-user">
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M775.083 319.747c0 145.297-117.786 263.083-263.083 263.083s-263.083-117.786-263.083-263.083c0-145.297 117.786-263.083 263.083-263.083s263.083 117.786 263.083 263.083z">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M208.443 542.356c-107.931 94.44-161.897 236.1-161.897 424.98">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M815.557 542.356c107.931 94.44 161.897 236.1 161.897 424.98">
                      </path>
                      <path fill="none" stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000" d="M46.545 967.336h930.909"></path>
                    </svg>
                  </div>
                </router-link>

                <!-- <button class="btn btn-primary" @click.prevent="showModalOne = !showModalOne">Open Shopping Cart</button> -->
                <a href="javascript:void(0)" class="Search" @click="isActive = !isActive">
                  <div class="Search">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024"
                      class="icon icon-search">
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M715.636 385.939c0 182.087-147.61 329.697-329.697 329.697s-329.697-147.61-329.697-329.697c0-182.087 147.61-329.697 329.697-329.697s329.697 147.61 329.697 329.697z">
                      </path>
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="round" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000" d="M696.242 696.242l271.515 271.515"></path>
                    </svg>
                  </div>
                </a>
                <!-- <a href="#" class="card-button" @click="showModal = !showModal"> -->
                <div>
                  <a class="card-button" v-if="!isLoggedIn" @click="login">
										<div class="Cart cart-icon">
											<p>{{ get_cart_count }}</p>
											<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 1365 1024" class="icon icon-cart">
												<path fill="#000" stroke-linejoin="miter" stroke-linecap="butt"
													stroke-miterlimit="4" stroke-width="21.3333" stroke="#000"
													d="M532.618 772.741c-57.987 0-105.431 46.933-105.431 104.296s47.444 104.296 105.431 104.296c57.987 0 105.431-46.933 105.431-104.296s-47.444-104.296-105.431-104.296zM532.618 946.568c-38.658 0-70.287-31.289-70.287-69.531s31.629-69.531 70.287-69.531c38.658 0 70.287 31.289 70.287 69.531s-31.629 69.531-70.287 69.531zM884.055 772.741c-57.987 0-105.431 46.933-105.431 104.296s47.444 104.296 105.431 104.296c57.987 0 105.431-46.933 105.431-104.296s-47.444-104.296-105.431-104.296zM884.055 946.568c-38.658 0-70.287-31.289-70.287-69.531s31.629-69.531 70.287-69.531c38.658 0 70.287 31.289 70.287 69.531s-31.629 69.531-70.287 69.531zM1128.304 251.259c-8.786-3.477-19.329 1.738-21.086 12.168l-128.275 422.4h-527.156l-180.99-596.227c-1.757-6.953-8.786-12.168-17.572-12.168h-184.505c-10.543 0-17.572 6.953-17.572 17.383s7.029 17.383 17.572 17.383h172.204l179.233 596.227c1.757 6.953 8.786 12.168 17.572 12.168h553.514c7.029 0 14.057-5.215 17.572-12.168l131.789-434.568c1.757-8.691-3.514-19.121-12.3-22.598z">
												</path>
											</svg>
										</div>
									</a>
                  <a href="javascript:void(0)" class="card-button" v-else @click.prevent="isActiveCart = !isActiveCart; cartButton()">
                    <div class="Cart cart-icon">
                      <p>{{ get_cart_count }}</p>
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 1365 1024"
                        class="icon icon-cart">
                        <path fill="#000" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4"
                          stroke-width="21.3333" stroke="#000"
                          d="M532.618 772.741c-57.987 0-105.431 46.933-105.431 104.296s47.444 104.296 105.431 104.296c57.987 0 105.431-46.933 105.431-104.296s-47.444-104.296-105.431-104.296zM532.618 946.568c-38.658 0-70.287-31.289-70.287-69.531s31.629-69.531 70.287-69.531c38.658 0 70.287 31.289 70.287 69.531s-31.629 69.531-70.287 69.531zM884.055 772.741c-57.987 0-105.431 46.933-105.431 104.296s47.444 104.296 105.431 104.296c57.987 0 105.431-46.933 105.431-104.296s-47.444-104.296-105.431-104.296zM884.055 946.568c-38.658 0-70.287-31.289-70.287-69.531s31.629-69.531 70.287-69.531c38.658 0 70.287 31.289 70.287 69.531s-31.629 69.531-70.287 69.531zM1128.304 251.259c-8.786-3.477-19.329 1.738-21.086 12.168l-128.275 422.4h-527.156l-180.99-596.227c-1.757-6.953-8.786-12.168-17.572-12.168h-184.505c-10.543 0-17.572 6.953-17.572 17.383s7.029 17.383 17.572 17.383h172.204l179.233 596.227c1.757 6.953 8.786 12.168 17.572 12.168h553.514c7.029 0 14.057-5.215 17.572-12.168l131.789-434.568c1.757-8.691-3.514-19.121-12.3-22.598z">
                        </path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div class="search-bar" :class="{ active: isActive }">
            <div class="search-inner-bar">
              <div class="container">
                <div class="form-group">
                  <span>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 1024 1024"
                      class="icon icon-search">
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="butt" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000"
                        d="M715.636 385.939c0 182.087-147.61 329.697-329.697 329.697s-329.697-147.61-329.697-329.697c0-182.087 147.61-329.697 329.697-329.697s329.697 147.61 329.697 329.697z">
                      </path>
                      <path fill="none" stroke-linejoin="miter" stroke-linecap="round" stroke-miterlimit="4"
                        stroke-width="69.8182" stroke="#000" d="M696.242 696.242l271.515 271.515"></path>
                    </svg>
                  </span>
                  <input type="text" name="search" class="form-control" placeholder="Search ..." @input="getSearchProducts"
                    v-model.trim="search">
                  <button class="btn close-button" @click="isActive = !isActive">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"
                      class="icon icon-close">
                      <path
                        d="M446.174 512l-432.542-432.542c-18.177-18.177-18.177-47.649 0-65.826s47.649-18.177 65.826 0l432.542 432.542 432.542-432.542c18.177-18.177 47.649-18.177 65.826 0s18.177 47.649 0 65.826l-432.542 432.542 432.542 432.542c18.177 18.177 18.177 47.649 0 65.826s-47.649 18.177-65.826 0l-432.542-432.542-432.542 432.542c-18.177 18.177-47.649 18.177-65.826 0s-18.177-47.649 0-65.826l432.542-432.542z">
                      </path>
                    </svg>
                  </button>
                </div>
                <!-- {{ products }} -->
                <div class="product_result text-start" v-if="products.length">
                  <h5 class="d-flex align-items-center justify-content-between">
                    <span>Products ({{ search_product_count }}) </span>
                    <router-link :to="{ name: 'search', query: { q: search } }">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 1024 1024"  class="icon icon-arrow-long">
                        <path d="M525.379 1020.065l114.69-110.279c7.645-7.353 7.645-18.379 0-25.732s-19.113-7.353-26.762 0l-82.194 77.195v-942.87c0-11.029-7.645-18.379-19.113-18.379s-19.113 7.353-19.113 18.379v942.87l-82.194-79.033c-7.645-7.353-19.113-7.353-26.762 0-3.823 3.676-5.734 9.191-5.734 12.868s1.911 9.191 5.734 12.868l114.69 110.279c7.645 7.353 19.113 7.353 26.762 1.838z">
                        </path>
                      </svg>
                    </router-link>
                  </h5>
                </div>
                <div class="row search-item-loader px-2">
									<div class="col-12">
										<div class="changingCssAnimation" :class="{ 'd-none': is_hide_loader }">
											<div class="lds-ellipsis">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div>
									</div>
								</div>
                <div class="row search-item px-2">
                  <div class="col-md-6 col-lg-3 search-item-col p-1" v-for="(product, key) in products.slice(0, 4)" :key="key">
                    <div class="card border-0" v-if="(product.images.length) > 0">
                      <div class="card-img image-redirect">
                        <img :src="product.images[0].url" alt="" class="search_image w-100">
												<router-link :to="{ name: 'product', params: { id: product.id } }">Quick View</router-link>
                        <!-- <img src="http://localhost:8080/assets/image/product-img-2.png" class="search_image" /> -->
                      </div>
                      <div class="card-body">
                        <h5>{{ product.name }}</h5>
                        <p>{{ product.prices.currency }} {{ product.prices.price }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cart-bar" :class="{ active: isActiveCart }">
            <div class="modal-inner-bar">
              <div class="">
                <div class="">
                  <cart-slice :key="componentKey"></cart-slice>
                  <button class="btn close-button cart-close-button" @click="isActiveCart = !isActiveCart">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 1024 1024"
                      class="icon icon-close">
                      <path
                        d="M446.174 512l-432.542-432.542c-18.177-18.177-18.177-47.649 0-65.826s47.649-18.177 65.826 0l432.542 432.542 432.542-432.542c18.177-18.177 47.649-18.177 65.826 0s18.177 47.649 0 65.826l-432.542 432.542 432.542 432.542c18.177 18.177 18.177 47.649 0 65.826s-47.649 18.177-65.826 0l-432.542-432.542-432.542 432.542c-18.177 18.177-47.649 18.177-65.826 0s-18.177-47.649 0-65.826l432.542-432.542z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="customModal" v-if="showModalOne">
      <div class="customModalTitle">
        <button class="closeCart" @click.prevent="showModalOne = !showModalOne">&times;</button>
        Shopping Cart
      </div>
      <div class="customModalBody">
        <cart-slice></cart-slice>
      </div>
    </div>

    <router-view> </router-view>
  </div>
</template>

<script>
import CartSlice from './Slices/CartSlice.vue';
import axios from 'axios';

export default {
  name: 'Headers-Prismic',
  components: {
    CartSlice
  },
  data() {
    return {
      isSticky: false,
      isActive: false,
      showModal: false,
      isActiveCart: false,
      isCartActive: false,
      stickyClass: 'active',
      scrollPosition: 0,
      text: '',
      showModalOne: false,
      products: [],
      search: "",
      search_product_count: "",
      categories: [],
			componentKey: 0,
			is_hide_loader: true,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.GET_AUTH_STATUS
    },
    get_cart_count() {
			return this.$store.getters.GET_CART_COUNT;
		}
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.getCategory();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    removeClassHover(){
      var element = document.getElementById("has-megamenu");
      element.classList.remove("has-megamenu");
    },
    handleScroll() {
      this.scrollPosition = window.scrollY
      // var body = document.body;
      if (this.scrollPosition >= 100) {
        this.isSticky = true
      } else {
        // body.classList.remove("active");
        this.isSticky = false
      }
    },

    toggle() {
      if (!this.isActive) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },

    getSearchProducts() {
      this.is_hide_loader = false;
      axios.get(process.env.VUE_APP_BASE_URL + "/search", { params: { name: this.search } })
        .then((response) => {
            this.products = response.data;
            this.search_product_count = response.data.length;
            this.is_hide_loader = true;

        }).catch((err) => {
            this.is_hide_loader = true;
            console.log(err);
        });
    },

    getCategory() {
        this.$store.dispatch("SHOW_CATEGORY")
          .then((response) => {
            console.log("SHOW_CATEGORY-HEADER-------->", response.data.categories);
            this.categories = response.data.categories;
          }).catch((err) => {
            console.log(err);
          });
    },
    cartButton() {
			this.componentKey += 1;
		},
    login() {
			this.$router.push({
				name: 'login',
			})
		}
  }
};

</script>


<style>

.has-megamenu:hover .dropdown-menu {
  display: block;
}

/* Define a class to hide megamenu */
.hide-megamenu {
  display: none;
}
.search_image {
  height: 450px;
  width: 334px;
}

.navbar .megamenu {
  padding: 1rem;
}

body:has(.cart-bar.active) {
  overflow: hidden;
}

@media all and (min-width: 992px) {

  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 85px;
    left: -75px;
    right: -75px;
    pointer-events: auto;
    transition: all 0.6s ease;
    width: 100vw;
    border-radius: 0;
    display: block !important;
    opacity: 0;
    visibility: hidden;
    border: none;
    max-width: 1350px;
    height: 600px;
    box-shadow: 0 0 10px rgb(141 141 141 / 50%)
  }

  .main-inner-header .dropdown-menu .inner-megamenu {
    max-width: 1840px;
    padding: 20px 40px;
    margin: 0 auto;
  }

  .header-botton.active .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 60px;
  }

  .main-inner-header .header-botton.active nav.navbar .collapse a.nav-link.dropdown-toggle::after {
    filter: none;
  }
}

@media(max-width: 991px) {

  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
}



.main-inner-header .header-top {
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  padding: 0 15px;
}

.main-inner-header .header-top p {
  font-family: "Work Sans";
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0px;
  margin: 7px 0;
}

.main-inner-header .header-top p a {
  text-decoration: underline;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
  color: #fff;
}

.main-inner-header .header-botton {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 99;
  transition: all 0.6s ease;
  margin-left: auto;
  width: 100%;
}

.main-inner-header nav.navbar {
  min-height: 120px;
  transition: all 0.6s ease;
}

.main-inner-header nav.navbar .navbar-collapse {
  width: 33.33%;
}

.main-inner-header nav.navbar a.logo {
  width: 33.33%;
}

.main-inner-header nav.navbar .right-panel {
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main-inner-header nav.navbar .right-panel>a {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
}

.main-inner-header nav.navbar .right-panel .icon path {
  stroke: #fff;
  transition: all 0.6s ease;
}

.main-inner-header nav.navbar .right-panel .Cart .icon path {
  fill: #ccc;
  transition: all 0.6s ease;
}

.main-inner-header nav.navbar .navbar-collapse a.nav-link {
  margin: 0 18px 0 0;
  padding: 5px 20px;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
  font-family: "Work Sans";
  font-weight: 400;
  color: #fff;
  line-height: 25px;
}

.main-inner-header nav.navbar .navbar-collapse li:first-child a.nav-link {
  padding-left: 0;
}

.main-inner-header nav.navbar .navbar-collapse a.nav-link.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg version=%271.1%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 viewBox=%270 0 1024 1024%27 class=%27icon icon-arrow%27%3E%3Cpath d=%27M926.553 256.428c25.96-23.409 62.316-19.611 83.605 7.033 20.439 25.582 18.251 61.132-6.623 83.562l-467.010 421.128c-22.547 20.331-56.39 19.789-78.311-1.237l-439.071-421.128c-24.181-23.193-25.331-58.79-4.144-83.721 22.077-25.978 58.543-28.612 83.785-4.402l400.458 384.094 427.311-385.33z%27%3E%3C/path%3E%3C/svg%3E");
  border: none;
  width: 12px;
  height: 12px;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(0) invert(1);
  transition: all 0.6s ease;
}

.main-inner-header nav.navbar ul li.has-megamenu:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.main-inner-header .dropdown-menu .inner-megamenu .mega-menu-img img {
  height: 500px;
  object-fit: cover;
  width: 500px;
}

.main-inner-header .header-botton:has(.has-megamenu:hover) {
  background-color: #fff;
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) .collapse a.nav-link {
  color: #000;
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) .collapse a.nav-link.dropdown-toggle::after {
  filter: none;
  transform: translateY(-50%) rotate(-180deg);
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) .right-panel .icon path {
  stroke: #000;
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) .right-panel .Cart .icon path {
  fill: #000;
}

.main-inner-header nav.navbar a.logo img {
  transition: all 0.6s ease;
  max-width: 300px;
  margin: 0 auto;
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) a.logo img {
  filter: brightness(0%);
}

.inner-megamenu .mega-menu-main {
  display: flex;
  flex-wrap: wrap;
}

.inner-megamenu .mega-menu-main .mega-menu-item {
  width: 25%;
}

.inner-megamenu .mega-menu-main .mega-menu-item a.header {
  padding: 5px 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  display: inline-block;
  color: #000;
}

.inner-megamenu .mega-menu-main .mega-menu-item ul {
  list-style: none;
}

.inner-megamenu .mega-menu-main .mega-menu-item ul li {
  padding-bottom: 15px;
  padding-right: 45px;
}

.inner-megamenu .mega-menu-main .mega-menu-item ul li a {
  line-height: 20px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
}

.inner-megamenu .mega-menu-main .mega-menu-item ul li a:hover {
  color: #000;
}

.nav-item>a,
.mega-menu-item a,
.nav-item>a {
  position: relative;
}

.main-inner-header nav.navbar .navbar-collapse a.nav-link::before,
.inner-megamenu .mega-menu-main .mega-menu-item a::before {
  content: "";
  display: block;
  border-bottom: 3px solid #fff;
  margin-bottom: -2px;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .2s ease-in-out;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
}

.main-inner-header nav.navbar:has(.has-megamenu:hover) .collapse a.nav-link::before,
nav.navbar:has(.has-megamenu:hover) .inner-megamenu .mega-menu-main .mega-menu-item a::before {
  border-color: #000;
}

.main-inner-header nav.navbar .navbar-collapse a.nav-link:hover::before,
.inner-megamenu .mega-menu-main .mega-menu-item a:hover::before {
  transform: scaleX(1);
  transform-origin: 0 50%;
}

.main-inner-header .dropdown-menu .inner-megamenu .mega-menu-img h4 {
  margin-top: 20px;
  position: relative;
  margin-bottom: 0;
  max-width: 500px;
}

.main-inner-header .dropdown-menu .inner-megamenu .mega-menu-img h4:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.main-inner-header .dropdown-menu .inner-megamenu .mega-menu-img h4 a {
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Work Sans";
  font-size: 15px;
  font-weight: 400;
  background-color: #fff;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
  padding-right: 10px;
}

.main-inner-header .header-botton.active {
  top: 0;
  background-color: #fff;
}

.main-inner-header .header-botton.active nav.navbar {
  min-height: 60px;
}

.main-inner-header .header-botton.active .right-panel .icon path {
  stroke: #000;
}

.main-inner-header .header-botton.active .right-panel .Cart .icon path {
  fill: #000;
}

.main-inner-header .header-botton.active .collapse a.nav-link {
  color: #000;
}

.main-inner-header .header-botton.active .right-panel .icon path {
  stroke: #000;
}

.main-inner-header .header-botton.active .right-panel .Cart .icon path {
  fill: #000;
}

.main-inner-header .header-botton.active nav.navbar a.logo img.normal-logo {
  display: none;
}

.main-inner-header .header-botton nav.navbar a.logo img.sticky-logo {
  display: none;
}

.main-inner-header .header-botton.active nav.navbar a.logo img.sticky-logo {
  display: block;
}

.navbar-expand-lg .navbar-collapse.d-lg-none {
  display: none !important;
}

.main-inner-header nav.navbar .navbar-collapse .close-button {
  display: none;
}

.search-inner-bar {
  min-height: 0;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
  height: 0;
  padding: 0;
}

.modal-inner-bar {
  min-height: 0;
  background-color: #fff;
  border-top: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
  height: 0;
  padding: 0;
}

.search-bar.active .search-inner-bar {
  min-height: 95px;
  opacity: 1;
  visibility: visible;
  height: 100%;
  padding: 10px 0;
}

.cart-bar.active .search-inner-bar {
  min-height: 95px;
  opacity: 1;
  visibility: visible;
  height: 100%;
  padding: 10px 0;
}

.search-bar.active .modal-inner-bar {
  min-height: 95px;
  opacity: 1;
  visibility: visible;
  height: 950px;
  padding: 10px 0;
  z-index: 99999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cart-bar.active .modal-inner-bar {
  min-height: 95px;
  opacity: 1;
  visibility: visible;
  height: 100vh;
  padding: 10px 0;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
}
.cart-main-section>.row {
  max-height: 70vh;
  overflow-y: auto;
}
.cart-close-button {
  position: absolute;
  top: 30px;
  right: 30px;
}

.search-inner-bar .form-group {
  display: flex;
  align-items: center;
}

.modal-inner-bar .form-group {
  display: flex;
  align-items: center;
}

.search-inner-bar .form-group input.form-control {
  border: none;
  font-size: 22px;
  line-height: normal;
  padding: 0 35px;
  width: 100%;
  border-radius: 0;
  color: #000;
  height: 100%;
  min-height: 40px;
}

.modal-inner-bar .form-group input.form-control {
  border: none;
  font-size: 22px;
  line-height: normal;
  padding: 0 35px;
  width: 100%;
  border-radius: 0;
  color: #000;
  height: 100%;
  min-height: 40px;
}

.search-inner-bar .form-group input.form-control::placeholder {
  color: #000;
  opacity: 1;
}

.web-header .main-inner-header .header-botton {
    top: 0;
}

.modal-inner-bar .form-group input.form-control::placeholder {
  color: #000;
  opacity: 1;
}

body:has(.search-bar.active) {
  overflow: hidden;
  position: relative;
}

body:has(.search-bar.active)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.5);
  z-index: 50;
}

.search-inner-bar .form-group button.btn.close-button:focus,
.search-inner-bar .form-group input.form-control:focus {
  box-shadow: none;
  border: none;
}

.modal-inner-bar .form-group button.btn.close-button:focus,
.modal-inner-bar .form-group input.form-control:focus {
  box-shadow: none;
  border: none;
}

.product_result h5 {
    font-size: 16px;
    margin: 0;
    border-bottom: 2px solid #8b8b8b;
    margin-bottom: 30px;
    margin-top: 10px;
}

.product_result h5 a {
  transform: rotate(270deg);
}
.search-item:has(.search-item-col) {
    height: calc(100vh - 290px);
    overflow-y: auto;
}
.search-item .card .card-img img{
  object-fit: cover;
}
.search-item .card .card-body h5 {
    font-size: 17px;
    font-weight: 400;
    color: #000;
}
.search-item .card .card-body p {
    font-size: 14px;
}
@media(max-height: 720px) {
  .main-inner-header .dropdown-menu .inner-megamenu .mega-menu-img img {
    height: 300px;
    width: 300px;
  }
}

@media(max-width: 1880px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: -12px;
    right: -70px;
  }
}

@media(max-width: 1280px) {
  .inner-megamenu .mega-menu-main .mega-menu-item ul li {
    padding-right: 10px;
  }
}

@media (max-width:1360px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu{
    height: 650px;
    width: 95vw;
  }
}

@media (max-width:1130px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu{
    height: 650px;
    width: 95vw;
  }
  .main-inner-header .dropdown-menu .inner-megamenu {
    padding: 20px 15px;
  }
}

@media (max-width:1015px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu{
    height: 650px;
    width: 95vw;
  }
  .main-inner-header .dropdown-menu .inner-megamenu {
    padding: 20px 15px;
  }
}


@media(max-width: 1180px) {
  .main-inner-header nav.navbar .navbar-collapse a.nav-link {
    margin: 0 5px 0 0;
  }

  .inner-megamenu .mega-menu-main .mega-menu-item ul li {
    padding-right: 15px;
  }
  .search-item .card .card-img img{
    height: 280px;
  }
}

@media(max-width: 991px) {
  .main-inner-header .header-botton button.navbar-toggler {
    width: 44px;
    height: 42px;
    background-color: #fff;
    border-radius: 0;
    border: none;
    padding: 0;
    transition: all 0.6s ease;
    margin-left: 20px;
  }

  .main-inner-header .header-botton button.navbar-toggler:focus {
    box-shadow: none;
  }

  .main-inner-header .header-botton button.navbar-toggler span {
    width: 24px;
    height: 2px;
    border-radius: 2px;
    transition: all .6s ease;
    background-color: #000;
    display: block;
    margin: 0 auto;
  }

  .main-inner-header .header-botton button.navbar-toggler span+span {
    margin-top: 5px;
  }

  .main-inner-header nav.navbar .right-panel .Search {
    display: none;
  }

  .main-inner-header nav.navbar a.logo img {
    max-width: 250px;
  }

  .main-inner-header:has(button.navbar-toggler[aria-expanded="true"]) nav.navbar a.logo img {
    max-width: 150px;
  }

  .inner-megamenu .mega-menu-main .mega-menu-item ul li {
    padding-right: 5px;
  }

  .mobile-menu-icon {
    width: 33.33%;
    text-align: left;
  }

  .main-inner-header nav.navbar .navbar-collapse {
    position: fixed;
    height: 100vh;
    width: 320px;
    left: 0;
    top: 0;
    background-color: #fff;
    display: block !important;
    transition: all 0.4s;
    transform: translateX(-100%);
    overflow-y: auto;
  }

  .main-inner-header nav.navbar .navbar-collapse.collapsing {
    height: 100vh !important;
  }

  .main-inner-header nav.navbar .navbar-collapse.show {
    transform: translateX(0px);
  }

  .main-inner-header nav.navbar .collapse a.nav-link {
    color: #222;
    font-size: 20px;
    margin: 7px 0 24px;
  }

  .main-inner-header nav.navbar .collapse a.nav-link::before,
  .inner-megamenu .mega-menu-main .mega-menu-item a::before {
    background-color: #222;
  }

  .main-inner-header nav.navbar .collapse a.nav-link.dropdown-toggle::after {
    filter: none;
  }

  body .main-page .homepage {
    transition: all 0.4s;
    width: 100%;
    margin-left: auto;
  }

  body .main-page:has(.main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"]) .homepage {
    /* transform: translateX(320px); */
    width: calc(100% - 320px);
  }

  .main-inner-header:has(.header-botton button.navbar-toggler[aria-expanded="true"]) .header-botton {
    width: calc(100% - 320px);
    margin-left: auto;
  }

  body:has(.web-header .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"]),
  html:has(.web-header .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"]) {
    overflow: hidden;
  }

  .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"] {
    transform: rotate(45deg);
  }

  .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }

  .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"] span:nth-child(1) {
    transform: translate(0px, 6px);
  }

  .main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
    transform: translate(0px, -8px) rotate(87deg);
  }

  .main-inner-header nav.navbar .navbar-collapse ul.navbar-nav {
    padding: 70px 45px;
    text-align: left;
    overflow-y: auto;
  }

  .main-inner-header nav.navbar .accordion-item {
    border: none;
    border-radius: 0;
  }

  .main-inner-header nav.navbar .accordion-item button.accordion-button {
    padding: 5px 20px;
    white-space: nowrap;
    position: relative;
    font-size: 20px;
    font-family: "Work Sans";
    font-weight: 400;
    color: #222;
    line-height: 25px;
    border: none;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
    margin: 7px 0 24px;
  }

  .main-inner-header nav.navbar .accordion-item .accordion-body button.accordion-button {
    font-size: 16px;
    margin: 0;
  }

  .main-inner-header nav.navbar .accordion-item button.accordion-button:focus {
    box-shadow: none;
  }

  .main-inner-header nav.navbar .accordion-item button.accordion-button:after {
    background-size: 16px;
    background-position: center;
    background-image: var(--bs-accordion-btn-icon) !important;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body {
    padding: 0 0 0 25px;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body button.accordion-button {
    border-bottom: 1px solid #e4e4e4 !important;
    padding: 12px 0 !important;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body button.accordion-button[aria-expanded="true"] {
    border: none !important;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body ul li a {
    word-break: break-word;
    font-weight: 400;
    font-size: 14px;
    padding: 12px 0;
    display: block;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body ul li a {
    border-top: 1px solid #e4e4e4;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body ul li:first-child a {
    border: none;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body ul {
    border: 1px solid #e4e4e4;
    padding: 12px 20px;
    margin-bottom: 10px;
    list-style: none;
  }

  .nav-user {
    border-top: 2px solid #000;
    padding: 33px 0 15px;
    font-size: 1.14rem;
    line-height: 25px;
    max-width: 230px;
    text-align: left;
    margin: 0 auto;
  }

  .nav-user ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .nav-user ul li a {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 17px;
  }

  .social-links {
    text-align: left;
    list-style: none;
    max-width: 230px;
    margin: 10px auto;
    padding: 0;
  }

  .nav-social .social-link .icon {
    width: 20px;
    height: 20px;
  }

  .main-inner-header nav.navbar .navbar-collapse.d-none.d-lg-block {
    display: none !important;
  }

  .search-bar {
    display: none;
  }

  body:has(.search-bar.active) {
    overflow: auto;

  }

  body:has(.search-bar.active)::before {
    display: none;
  }
}

@media(max-width: 767px) {
  .mobile-menu-icon {
    width: auto;
  }

  body .main-page:has(.main-inner-header .header-botton button.navbar-toggler[aria-expanded="true"]) .homepage {
    width: 100%;
    opacity: 0;
    visibility: hidden;
  }

  .main-inner-header:has(.header-botton button.navbar-toggler[aria-expanded="true"]) .header-botton {
    width: 100%;
  }

  .main-inner-header nav.navbar .navbar-collapse {
    width: 100%;
    padding: 0 40px;
  }

  .main-inner-header nav.navbar .navbar-collapse.show {
    transform: translateX(0%);
    width: 100%;
  }

  .main-inner-header nav.navbar .navbar-collapse .close-button {
    display: block;
    text-align: right;
    padding-top: 20px;
  }

  .nav-user,
  .social-links {
    max-width: 100%;
    padding: 20px;

  }

  .main-inner-header nav.navbar .navbar-collapse ul.navbar-nav {
    padding: 70px 0;
  }

  .main-inner-header .header-botton button.navbar-toggler {
    margin-left: 0;
  }
}

@media(max-width: 520px) {

  .main-inner-header nav.navbar a.logo img {
    max-width: 120px;
  }

  .main-inner-header nav.navbar .navbar-collapse {
    padding: 0 15px;
  }

  .main-inner-header nav.navbar .collapse a.nav-link,
  .main-inner-header nav.navbar .accordion-item button.accordion-button {
    padding: 5px;
    margin: 7px 0;
  }

  .navbar-expand-lg .navbar-collapse .accordion-body {
    padding-left: 10px;
  }

  .main-inner-header nav.navbar .navbar-collapse ul.navbar-nav {
    padding: 40px 0;
  }

  .cart-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}


.customModal {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
  z-index: 999;
  top: 20vh;
  border-radius: 5px;
  overflow: hidden;
}

.customModal .customModalTitle {
  background-color: #eee;
  text-align: left;
  padding: 8px 12px;
  font-size: 1.5em;
}

.customModal .customModalTitle .closeCart {
  line-height: 32px;
}

.dropdown-menu .megamenu{
  max-width: 1840px;
}

.customModal .customModalBody {
  background-color: #fff;
  padding: 8px 12px;
  text-align: left;
  padding: 12px;
}
.cart-icon p {
  background-color: hsla(0, 0%, 45%, .9);
  width: 15px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: 42px;
  right: 0px;
  transition: all 0.6s ease;
}
.header-botton.active .cart-icon p {
  top: 8px;
}
.changingCssAnimation {
  text-align: center;
  transition: opacity 1s ease-out;
  opacity: 1
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:first-child {
  left: 8px;
  -webkit-animation: lds-ellipsis1 .6s infinite;
  animation: lds-ellipsis1 .6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
}

.lds-ellipsis div:nth-child(2),
.lds-ellipsis div:nth-child(3) {
  -webkit-animation: lds-ellipsis2 .6s infinite;
  animation: lds-ellipsis2 .6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 .6s infinite;
  animation: lds-ellipsis3 .6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  to {
    -webkit-transform: translate(24px);
    transform: translate(24px);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  to {
    -webkit-transform: translate(24px);
    transform: translate(24px);
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.search-item .card .card-img a {
  display: inline-block;
  font-weight: 400;
  transition: opacity .2s ease-in-out;
  cursor: pointer;
  padding: 10px 30px;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none !important;
  letter-spacing: 0px;
  background-color: #ffffff;
  color: #000;
  border: 1px solid #ffffff;
  border-radius: 0;
  min-width: 150px;
  text-align: center;
  margin: 10px 10px 0;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
}

.search-item .card:hover .card-img a {
  opacity: 1;
  visibility: visible;
}

/* apply media query for laptop and desktop
 */



</style>
