import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue} from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/style.css';
import VueEasyLightbox from 'vue-easy-lightbox'
import Toaster from "@meforma/vue-toaster";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import vueCountryRegionSelect from 'vue3-country-region-select'
import VueCreditCardValidation from 'vue-credit-card-validation';
let app = createApp(App)
app.config.globalProperties.globalUrl = 'https://dxpro.asia/api'
app.use(store)
app.use(BootstrapVue)
app.use(VueEasyLightbox)
app.use(router)
app.use(Toaster, {
    // One of the options
    position: "top-right",
})
app.use(VueTelInput);
app.use(vueCountryRegionSelect);
app.use(VueCreditCardValidation);
app.mount('#app')
