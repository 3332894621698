<template>
  <section class="cta-section">
    <div class="cta-inner-section">
      <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="cta-top-title">SIMPLE FRESH</div>
                <div class="cta-description">Food Foundry started out making mille crepes in 2007 and today, we <br>continue our tradition of handmade mille crepes with flavours ranging from <br>classic to fun to decadent like our salted caramel.</div>
                <div class="cta-bottom-title">Our philosophy has always been simple food done well.</div>
                <router-link to="/about" class="ct-button">
                    About Us +
                </router-link>
                <!-- <b-button v-b-modal="'my-modal'">Show Modal</b-button> -->
            </div>
        </div>  

       

  <!-- The modal -->
  <!-- <b-modal id="my-modal">Hello From My Modal! </b-modal> -->
      </div>
    </div>
  </section>
</template>

<script>

// import CartSlice from './CartSlice.vue'
// import { BModal, BButton } from 'bootstrap-vue-next'
export default {
  name: 'Call to action',
components: {
    // CartSlice,
    // BModal,
    // BButton
  },
  data() {
    return {
      showModal: false,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cta-inner-section {
    padding: 100px 0;
}
.cta-inner-section .col-sm-12 {
    max-width: 1075px;
    margin: 0 auto;
}
.cta-inner-section .cta-top-title {
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
.cta-inner-section .cta-description {
    font-size: 32px;
    font-family: "PT Sans";
    line-height: 1.4;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 0.2em;
}
.cta-inner-section .cta-bottom-title {
    font-size: 14px;
    margin-bottom: 14px;
}
@media(max-width: 991px){
  .cta-inner-section .cta-description {
    max-width: 571px;
  }
}
@media(max-width: 520px){
  .cta-inner-section .cta-description {
    font-size: 25px;
  }
  .cta-inner-section{
    padding: 60px 0;
  }
}
</style>
