export const product_record = {
    records: [
        { 
            'image': '/assets/product/Chocolate.jpg',
            'name': 'Chocolate',
            'price': '98.00',
            'qty': 1,
            'total': "RM48.00 MYR"
        },
        { 
            'image': '/assets/product/Almond_Salted_Caramel.jpg',
            'name': 'Almond Salted Caramel',
            'price': '30.00',
            'qty': 1,
            'total': "RM48.00 MYR",
        },
    ],
    products : [
        {
            'name' : 'Chocolate',
            'price': '98.00',
            'detail01': 'A chocolate version of our classic mille crepe. Paper thin cocoa crepes are layered with dark chocolate ganache.',
            'detail02': 'This 6” cake (±850g) serves 6 – 8 people.',
            'ingredients' : 'Butter, cocoa powder, corn flour, cream, cream cheese, dark chocolate, eggs, flour, salt, sugar, vanilla bean, whole milk',
            'qty' : 1,
            images : ['/assets/product/Chocolate.jpg', '/assets/product/Almond_Salted_Caramel.jpg']
        }
    ],
    address : [
        {
          "id" : 1,
          "name" : "myNews(Maru kafe) @ Ara kiri Lucky Garden",
          "address" : "32, Persiaran Ara Kiri Kuala Lumpur, 59100",
        },
        {
          "id" : 2,
          "name" : "myNews(Maru kafe) @ SS20/11 Damansara Kim",
          "address" : "43, Jalan SS20/11, Damansara KIM PETALING JAYA, 47400",
        },
      ],
  }